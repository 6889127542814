import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const StrawHair = () => (
  <Layout>
    <SEO
      title="Why Does My Natural Hair Feel Dry Like Straw?"
      desciption="The key to having beautiful healthy hair that isn’t dry is to have a routine that you have built out through experimentation that matches your hair type"
    />
    <h1>Why Does My Natural Hair Feel Dry Like Straw?</h1>

    <p>This is a question that gets asked much more than you would imagine so don’t worry it’s not just you. The reason we decided to write this is because we get asked so much and we thought, what better way to help than to write out the advice we usually give to help everyone that hasn’t reached out yet.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/URmEKMt.jpg"
        alt="Why Does My Natural Hair Feel Dry Like Straw?"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@dancristianpaduret"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Dan-Cristian Pădureț</a></p>
    </div>

    <p>There can be a few reasons your hair feels like straw and this is not just restricted to certain hair types, all hair types can suffer from the same problem.</p>
    <p>I will write the solutions in order of how frequently I come across the problem. This way when you read something you haven’t tried yet, you can try it for a few days to see how it goes. If you are still having a problem then come back and try the next thing in the list.</p>
    <p>First and foremost, the reason you are asking, “why does my hair feel like straw?” is because….</p>
    <p>You are not following the correct routine for your hair type.</p>
    <p>I want to make it clear that there is no single correct hair routine for everyone. Multiple will work and you haven’t found one that works for you yet. A hair routine is the whole process from start to finish. Not just for a day but for a whole cycle which can be a month long. Products and steps you use on day 1 might be different to those on day 14 and again, different on day 28.</p>
    <p>The key to having beautiful healthy hair that isn’t dry is to have a routine that you have built out through experimentation that matches your hair type. I say experimentation because even for the most experienced of us, things change over time and we have to be really lucky to find something that works on the very first try.</p>

    <h2>You need a leave-in conditioner</h2>
    <p>A leave-in conditioner is the thing you add to your hair to help retain moisture. A lot of people only need a leave-in conditioner in their post-wash routine.</p>
    <p>The problem with water for hydration alone is that it evaporates very quickly and doesn’t leave anything of benefit behind. Somewhere between fully drenched after being washed and fully dry is a great place to be and this is where leave-in conditioners help us stay.</p>
    <p>Each is designed with the same purpose in mind; hydration. But each is also designed in such a way that will bring different results. Some give more visible shine, some give higher levels of hydration, others allow for more slip and easier detangling.</p>
    <p>This is where reading marketing material, reading reviews and experimenting with different products will help you find a leave-in conditioner that does exactly what you want it to do.</p>

    <h2>You need oil</h2>
    <p>Oil is used as an extra barrier for sealing moisture. Leave-in conditioner leaves different hair types at different speeds. This means that some hair types can make it through a day, two or even more with a single use of leave-in conditioner.</p>
    <p>More absorbent hair types and those in certain hot climates might find that their leave-in conditioners aren’t taking them through the whole day consistently. This is where the use of oils comes in.</p>

    <h2>You need a deep conditioner</h2>
    <p>Deep conditioner is what you use to bring the lost nutrients back to your hair. Sometimes it is used as a remedy to revitalise your hair. After a good deep condition, you will notice your hair is visibly more healthy and will feel soft to touch. Other times it can be used to help with long term maintenance in order to stop your hair falling into a bad state in the first place.</p>
    <p>The process of deep conditioning is a long one. It isn’t complex as you can buy deep conditioners ready made or make your own quite simply with a few ingredients you can buy from most food places. The long process comes from the time it takes for the deep conditioner to work its magic.</p>
    <p>You have to make space in your day and dedicate at least half an hour for the deep conditioning alone. Deep conditioners are not leave-in so they will need to be washed out after. Meaning you need more time to wash and style afterwards.</p>
    <p>After your first time deep conditioning, you should see some results. The benefits come from continuous use where your hair has the chance to keep absorbing the nutrients it needs for long term benefits. In other words, don’t expect the world on the first go. Keep going with this routine and after a few cycles, you should notice a big difference and you can make the choice to keep it up or reduce the frequency and find other hair health preserving methods. Instead of fixing hair that is already damaged.</p>

    <h2>Conclusion</h2>
    <p>Having hair that feels like straw can have many different causes and will vary from person to person. It will also vary depending on the time of the year. Straw hair in the summer can be as a result of harsh direct sunlight and extreme heat evaporating your moisture whereas the winter icy winds can blast your moisture away giving the same results.</p>
    <p>As you gain experience in combating the problem using the tips I’ve mentioned above, you should make a note of what worked and what didn’t. This way you won’t forget and end up repeating the same mistakes over and over again like so many other people.</p>
    <p>Keep a diary and make a note of the temperature outside, what day you washed your hair. What your post wash routine was, exactly which products you used and a rough idea about how much, even the order in which you used the products if you wanted to. Trust me, it helps to have a reference to go back to because in all the experimentation, it’s easy to forget.</p>
    <p>In the end, don’t be too stressed out about finding a solution because eventually you will. You will learn how to work best with your hair so it can be healthy and you can feel happy about your crown.</p>
    <p>Good luck and let me know what worked for you when you do find a solution.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default StrawHair
